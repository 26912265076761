<template>
  <div>
    <PageHeader
      :title="`Groupes > ${domain.label}`"
      description="Gestion des groupes"
      :button="{
        icon: 'fas fa-plus-circle',
        text: 'Ajouter un groupe',
        action: openModal
      }"
    />

    <PageContent>
      <div
        v-if="loading"
        class="fixed top-0 left-0 w-screen h-screen bg-white bg-opacity-50 z-50 cursor-wait"
        />
      <MVPTable
        :gridData="gridData"
        :options="gridOptions"
      />
    </PageContent>

    <t-modal
      name="group-form"
      variant="contentForm"
    >
      <MVPGroupsForm
        v-if="currentGroup"
        :group="currentGroup"
        :groupKey="currentGroup.uuid"
        @refresh="getGroups"
      />
    </t-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import MVPGroupsForm from './Form.vue'

export default {
  name: 'app-groups-list',
  components: {
    PageHeader,
    PageContent,
    MVPTable,
    MVPGroupsForm
  },
  computed: {
    ...mapGetters([
      'roles',
      'domain'
    ]),
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    },
    gridOptions () {
      return {
        columnDefs: [
          {
            headerName: 'Nom',
            field: 'label',
            filter: 'agSetColumnFilter',
            editable: true,
            sortable: true,
            onCellValueChanged: (data) => {
              http
                .post(`/groups/${data.data.uuid}`, {
                  label: data.newValue
                })
                .then(res => {
                  this.$notify({
                    group: 'maviepro-success',
                    text: 'Groupe mis à jour.'
                  })
                })
                .catch(err => {
                  console.log(err)
                  this.$notify({
                    group: 'maviepro-error',
                    text: 'Erreur lors de la sauvegarde du groupe.'
                  })
                })
            }
          },
          {
            cellRenderer: 'IconCellRenderer',
            cellRendererParams: {
              cellClass: 'text-primary-400 hover:text-primary-600',
              iconClass: 'fas fa-users',
              onClick: this.goToUsers
            },
            width: 40,
            hide: !this.hasEditionRights
          },
          {
            cellRenderer: 'IconCellRenderer',
            cellRendererParams: {
              cellClass: 'text-red-400 hover:text-red-600',
              iconClass: 'fas fa-trash',
              onClick: this.deleteRow
            },
            width: 40,
            hide: !this.hasEditionRights
          }
        ]
      }
    }
  },

  data () {
    return {
      loading: false,
      groups: [],
      groupKey: null,
      currentGroup: null,
      gridData: null,
      stats: null
    }
  },

  beforeMount: async function () {
    if (this.$route.params.uuid) {
      this.groupKey = this.$route.params.uuid
      await http.get(`/groups/${this.groupKey}`).then(res => {
        this.group = res.data
        this.groupKey = res.data.uuid
        this.openModal(this.group)
      }).catch(err => {
        let message
        if (err && err.response.status === 404) {
          message = 'Le groupe demandé est introuvable'
        } else {
          message = 'Erreur de chargement de le groupe'
        }

        this.$buefy.notification.open({
          duration: 3000,
          message,
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    }
  },
  mounted () {
    this.getGroups()
  },
  watch: {
    domain (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getGroups()
      }
    }
  },
  methods: {

    getGroups () {
      this.loading = true
      http.get('/groups')
        .then(res => {
          this.loading = false
          this.gridData = res.data
          this.groups = res.data
          // this.getDuplicationStatistics()
        }).catch(err => {
          this.loading = false
          console.log(err)
          this.$buefy.notification.open({
            duration: 3000,
            message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des groupes',
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        })
    },

    openModal ({ data }) {
      if (!this.hasEditionRights) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Vous n\'avez pas les droits pour modifier un groupe',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
        return
      }
      if (data) {
        this.currentGroup = data
      } else {
        this.currentGroup = {}
      }
      this.$modal.show('group-form', data)
    },

    goToGroup ({ data }) {
      this.$router.push({ path: `/app/groups/${data.uuid}` })
    },

    deleteRow (rowData) {
      if (confirm(`Êtes-vous sûr de vouloir supprimer ce groupe : ""${rowData.label}"" ?`)) {
        this.loading = true
        http.delete(`/groups/${rowData.uuid}`).then(res => {
          this.loading = false
          this.gridData = this.gridData.filter(item => {
            return item.uuid !== rowData.uuid
          })
          this.$buefy.notification.open({
            duration: 3000,
            message: `Le groupe "<strong>${rowData.label}</strong>" a bien été supprimé`,
            position: 'is-bottom-right',
            type: 'is-success',
            hasIcon: false
          })
        })
      } else {
        this.$buefy.notification.open({
          duration: 3000,
          message: `Annulation de la suppression du groupe ""<strong>${rowData.label}</strong>""`,
          position: 'is-bottom-right',
          type: 'is-warning',
          hasIcon: false
        })
      }
    },

    goToUsers (rowData) {
      this.$router.push({
        name: 'app-group-users',
        params: {
          uuid: rowData.uuid
        }
      })
    }

  }
}
</script>
