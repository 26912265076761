<template>
  <div v-if="groupData">
    <div class="border-b p-3 rounded-t bg-gray-100">
      <h3 class="text-lg text-gray-900">
        {{ groupData.uuid ? 'Modification' : 'Création' }} d'un groupe
      </h3>
    </div>
    <div class="space-y-6 bg-white sm:p-6">
      <t-input-group label="Nom">
        <t-input
          v-model="groupData.label"
          type="text"
          placeholder="Nom du groupe"
        />
      </t-input-group>

    </div>

    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between border-t border-t-gray-300">
      <div class="flex gap-3 items-center">
        <t-button
          v-if="groupData.uuid"
          @click="deleteGroup"
        >
          Gestion des utilisateurs
        </t-button>
      </div>
      <div class="flex gap-3">
        <t-button
          v-if="groupData.uuid"
          class="m-0"
          @click.prevent="deleteGroup"
          variant="error"
        >
          Supprimer
        </t-button>
        <t-button
          @click.prevent="saveGroup"
          variant="success"
        >
          Sauvegarder
        </t-button>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import French from 'vue-tailwind/dist/l10n/fr'

export default {
  name: 'mvp-groups-form',
  data () {
    return {
      groupData: {},
      groupUniqueKey: null,
      locale: French
    }
  },
  props: [
    'group',
    'groupKey'
  ],
  beforeMount: async function () {
    this.groupUniqueKey = typeof this.$route.params.uuid !== 'undefined'
      ? this.$route.params.uuid : this.groupKey
    // group loading
    if (typeof this.groupUniqueKey !== 'undefined') {
      await http.get(`/groups/${this.groupUniqueKey}`)
        .then(({ data }) => {
          this.groupData = data
        })
        .catch(err => {
          console.log(err)
          if (err && err.response.status === 404) {
            this.$notify({
              group: 'maviepro-error',
              text: 'Groupe introuvable.'
            })
          } else {
            this.$notify({
              group: 'maviepro-error',
              text: 'Impossible de récupérer le groupe.'
            })
          }
          this.$parent.$modal.hide('group-form')
        })
    }
  },
  methods: {
    saveGroup () {
      const formData = new FormData()
      const isUpdate = !!this.groupData.uuid
      console.log('isUpdate', isUpdate)
      const { ...data } = this.groupData
      const postUrl = '/groups' + (isUpdate ? `/${this.groupData.uuid}` : '')
      for (const prop in data) {
        formData.append(prop, data[prop])
      }
      http.post(postUrl, formData).then(res => {
        this.$emit('refresh')
        this.$parent.$modal.hide('group-form')
        this.$notify({
          group: 'maviepro-success',
          text: 'Groupe mis à jour.'
        })
        if (this.$router.currentRoute.path !== '/app/groups/') {
          this.$router.push({ path: `/app/groups/` })
        }
      }).catch(err => {
        console.log(err)
        this.$notify({
          group: 'maviepro-error',
          text: 'Erreur lors de la sauvegarde du groupe.'
        })
      })
    },
    deleteGroup () {
      this.$dialog.confirm({
        title: 'Supprimer ce groupe ?',
        text: 'Cette action ne peut pas être annulée.',
        icon: 'warning',
        cancelButtonText: 'Annuler',
        okButtonText: 'Supprimer'
      }).then((result) => {
        if (result.isOk && result.hideReason === 'ok') {
          http.delete(`/groups/${this.groupUniqueKey}`).then(res => {
            this.$notify({
              group: 'maviepro-success',
              text: 'Groupe supprimé.'
            })
            this.$emit('refresh')
            this.$parent.$modal.hide('group-form')
          }).catch(err => {
            console.log(err)
            this.$notify({
              group: 'maviepro-error',
              text: 'Problème lors de la suppression, veuillez contacter un administrateur.'
            })
          })
        }
      })
    }
  }
}
</script>
